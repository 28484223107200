import React from 'react';
import './index.css';
import App from './App';
import * as ReactDOMClient from 'react-dom/client';
import "@nike/eds/dist/index.css";
import {BrowserRouter} from "react-router-dom";
import store from './shared/store/Store';
import { Provider } from 'react-redux';

let localClearanceOverviewContainer = null;
let root = null;

window.renderLocalClearanceOverview = (containerId) => {
    if (!localClearanceOverviewContainer) {
        localClearanceOverviewContainer = document.getElementById(containerId);
        root = ReactDOMClient.createRoot(localClearanceOverviewContainer);
        root.render(
            <React.StrictMode>
                <BrowserRouter>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </BrowserRouter>
            </React.StrictMode>
        );
    }
}

window.unmountLocalClearanceOverview = containerId => {
    root.unmount();
    localClearanceOverviewContainer = null;
    root = null;
}