import gql from "graphql-tag";

import { OverviewAppSyncClient } from "../clients/OverviewAppSyncClient";
import { findBatchesByMonth } from "../../graphql/queries";
import { createAndProduceBatches } from "../../graphql/mutations";
import { LocalClearanceBatch, MonthOption } from "../Types";

export class OverviewService {

    constructor(
        private overviewAppSyncClient: OverviewAppSyncClient
    ) {}

    async findBatchesByMonth({month, year}: MonthOption): Promise<any> {
        return this.overviewAppSyncClient.appSyncClient.query({
            query: gql(findBatchesByMonth), 
            fetchPolicy: 'network-only', 
            variables: {month, year}
        })
    }

    async createAndProduceBatches(batches : LocalClearanceBatch[]): Promise<any> {
        return this.overviewAppSyncClient.appSyncClient.mutate({
            mutation: gql(createAndProduceBatches), 
            variables: {
                dates: batches.map(x => x.day_bucket_date)
            }
        })
    }

}