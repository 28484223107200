import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { LocalClearanceBatch, MonthOption } from '../../Types';
import { OverviewAppSyncClient } from '../../clients/OverviewAppSyncClient';
import { OverviewService } from '../../services/OverviewService';
import { oktaAuth } from '../../config/OktaConfig';

const createMonthOptions = () => {
  const currentDateForMonth = new Date();
  const previousDateForMonth = new Date()
  previousDateForMonth.setDate(0);

  return [{
    month: currentDateForMonth.getMonth() + 1,
    year: currentDateForMonth.getFullYear(),
  }, {
    month: previousDateForMonth.getMonth() + 1,
    year: previousDateForMonth.getFullYear(),
  }] as MonthOption[];
}

const getAllBatchesInMonth = (monthOption: MonthOption, batches: LocalClearanceBatch[]) => {
  const batchMap = new Map<string, LocalClearanceBatch>();

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const daysInMonth = new Date(monthOption.year, monthOption.month - 1, 0).getDate();

  for (let day = 1; day <= daysInMonth; day++) {
    const date = new Date(monthOption.year, monthOption.month - 1, day);
    if (date >= yesterday) {
        break;
    }

    const dayBucketDate = [monthOption.year, monthOption.month.toString().padStart(2, '0'), day.toString().padStart(2, '0')].join('-');

    batchMap.set(dayBucketDate, {
      day_bucket_date: dayBucketDate,
      status: 'NEW',
      selected: false
    } as LocalClearanceBatch);
  }

  batches.forEach((batch) => {
    batchMap.set(batch.day_bucket_date, {
      ...batch,
      selected: false
    });
  });

  return Array.from(batchMap.values());
}

export const findBatchesByMonth = createAsyncThunk('findBatchesByMonth', async (monthOption : MonthOption) => {
  return await overviewService.findBatchesByMonth(monthOption);
});

export const createAndProduceBatches = createAsyncThunk('createAndProduceBatches', async (batches : LocalClearanceBatch[]) => {
  return await overviewService.createAndProduceBatches(batches);
});

const overviewAppSyncClient: OverviewAppSyncClient = new OverviewAppSyncClient(oktaAuth);
const overviewService: OverviewService = new OverviewService(overviewAppSyncClient);
const monthOptions = createMonthOptions();

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    isLoading: true,
    batches: [] as LocalClearanceBatch[],
    monthOptions: monthOptions,
    activeMonthOption: monthOptions[0],
    isModalVisible: false,
    notification: null as any
  },
  reducers: {
    changeMonth: (state, action) => {
      state.activeMonthOption = action.payload;
    },
    toggleBatch: (state, action) => {
      const batch = state.batches.find(batch => {
        return batch.day_bucket_date === action.payload.day_bucket_date;
      }) as LocalClearanceBatch;

      batch.selected = !batch.selected;
    },
    toggleModalConfirmation: (state, action) => {
      state.isModalVisible = action.payload;
    },
    toggleNotification: (state, action) => {
      state.notification = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findBatchesByMonth.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(findBatchesByMonth.fulfilled, (state, action) => {
      state.isLoading = false;
      state.batches = getAllBatchesInMonth(state.activeMonthOption, action.payload.data.findBatchesByMonth as LocalClearanceBatch[]);
    }).addCase(createAndProduceBatches.pending, (state) => {
      state.isLoading = true;
    }).addCase(createAndProduceBatches.rejected, (state, action: any) => {
      state.isLoading = false;
      state.isModalVisible = false;
      state.notification = {
        status: 'error',
        message: action.error.message
      };
    })
    .addCase(createAndProduceBatches.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isModalVisible = false;
      state.notification = {
        status: 'success',
        message: 'Batches successfully released for declaration',
      };

      action.payload.data.createAndProduceBatches.forEach((x: LocalClearanceBatch) => {
        const index = state.batches.findIndex((y: LocalClearanceBatch) => y.day_bucket_date === x.day_bucket_date);

        state.batches[index] = x;
      });
    });
  },
});

export const {
  changeMonth,
  toggleBatch,
  toggleModalConfirmation,
  toggleNotification
} = overviewSlice.actions;

export const selectBatches = (state: any) => state.overview.batches;
export const selectNotification = (state: any) => state.overview.notification;
export const selectIsLoading = (state: any) => state.overview.isLoading;
export const selectIsModalConfirmationVisible = (state: any) => state.overview.isModalVisible;
export const selectMonthOptions = (state: any) => state.overview.monthOptions;
export const selectActiveMonthOption = (state: any) => state.overview.activeMonthOption;

export const selectCheckedBatches = createSelector(
  [selectBatches], (batches) => batches.filter((batch: LocalClearanceBatch) => batch.selected)
);

export default overviewSlice.reducer;