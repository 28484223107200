import { configureStore } from '@reduxjs/toolkit';

import OverviewReducer from './reducers/OverviewReducer';

const store = configureStore({
  reducer: {
    overview: OverviewReducer,
  },
});

export type StoreDispatch = typeof store.dispatch;

export default store;