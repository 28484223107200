import "./BatchStatusIndicator.css";

import { StatusIndicator, Tooltip } from '@nike/eds';
import { LocalClearanceBatch } from '../../shared/Types';

export function BatchStatusIndicator({status, updated_at}: LocalClearanceBatch) {
    const updatedAt =
        <span className="eds-type--legal-1 eds-color--secondary updated-at">
            {new Date(Date.parse(updated_at + " GMT")).toLocaleString()}
        </span>;
    switch (status) {
        case "PENDING":
            return <div>
                <StatusIndicator label="Release in pending" status="neutral" className="indicator-base-margin"/>
                {updatedAt}
            </div>
        case "PROCESSING":
            return <div>
                <StatusIndicator label="Release in progress" status="warning" className="indicator-base-margin"/>
                {updatedAt}
            </div>
        case "COMPLETED":
            return <div>
                <StatusIndicator label="Released successfully" status="success" className="indicator-base-margin"/>
                {updatedAt}
            </div>
        case "FAILED_SEND":
            return <div className="error-indicator-container">
                <div className="error-indicator indicator-base-margin">
                    <StatusIndicator label="Release error" status="danger"/>
                    <Tooltip bodySlot="Failed to send batch" placement={"top"}><span className="info-icon">&#9432;</span></Tooltip>
                </div>
                {updatedAt}
            </div>
        case "FAILED_CREATE":
            return <div className="error-indicator-container">
                <div className="error-indicator indicator-base-margin">
                    <StatusIndicator label="Release error" status="danger"/>
                    <Tooltip bodySlot="Failed to create batch" placement={"top"}><span className="info-icon">&#9432;</span></Tooltip>
                </div>
                {updatedAt}
            </div>
        default:
            return <div className="eds-type--subtitle-2">
                Not released
            </div>
    }
}