/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createAndProduceBatches = /* GraphQL */ `mutation CreateAndProduceBatches($dates: [String]) {
  createAndProduceBatches(dates: $dates) {
    day_bucket_date
    status
    user_id
    number_of_transactions
    updated_at
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAndProduceBatchesMutationVariables,
  APITypes.CreateAndProduceBatchesMutation
>;
