/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./api";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const findBatchesByMonth = /* GraphQL */ `query FindBatchesByMonth($month: Int, $year: Int) {
  findBatchesByMonth(month: $month, year: $year) {
    day_bucket_date
    status
    user_id
    number_of_transactions
    updated_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FindBatchesByMonthQueryVariables,
  APITypes.FindBatchesByMonthQuery
>;
