import { OktaAuth } from '@okta/okta-auth-js'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';

export class OverviewAppSyncClient {

    appSyncClient: AWSAppSyncClient<any>;

    constructor(private oktaAuth: OktaAuth) {
        this.appSyncClient = new AWSAppSyncClient({
            url: (window as any).env !== undefined ? (window as any).env.REACT_APP_APPSYNC_LOCAL_CLEARANCE_ENDPOINT : 'https://dummygraphql.com',
            region: 'eu-west-1',
            auth: {
                type: AUTH_TYPE.OPENID_CONNECT,
                jwtToken: async () => oktaAuth.getAccessToken()!,
            },
            disableOffline: true
        });
    }
}