import './local-clearance-overview.css';

import { Snack, Snackbar } from "@nike/eds";
import { useSelector, useDispatch } from 'react-redux';
import { BatchTable } from '../components/batch_table/BatchTable';
import { ConfirmationModal } from '../components/confirmation_model/ConfirmationModal';
import { ActionContainer } from '../components/action_container/ActionContainer';
import { selectNotification, toggleNotification } from '../shared/store/reducers/OverviewReducer';
import { StoreDispatch } from '../shared/store/Store';
import { Notification } from '../shared/Types';

export function LocalClearanceOverview() {
    const dispatch: StoreDispatch = useDispatch();
    const notification: Notification = useSelector(selectNotification);

    const onClickDismissSnack = () => {
        dispatch(toggleNotification(null));
    }

    console.log('Render overview');

    return (
        <div id="main-container">
            <Snackbar>
                {notification && (
                    <Snack id="snack" status={notification.status} onDismiss={onClickDismissSnack} autoDismissDuration={15000}>
                        <p className="eds-type--body-2">{notification.message.replace(/^.+?(:) /, '')}</p>
                    </Snack>
                )}
            </Snackbar>
            <ActionContainer/>
            <BatchTable/>
            <ConfirmationModal/>
        </div>
    )
}