import './MonthSelection.css';
import { Tab, TabGroup } from '@nike/eds'
import { useSelector, useDispatch } from 'react-redux';
import { changeMonth, findBatchesByMonth, selectActiveMonthOption, selectMonthOptions } from '../../shared/store/reducers/OverviewReducer';
import { StoreDispatch } from '../../shared/store/Store';
import { MonthOption } from '../../shared/Types';
import { useEffect } from 'react';

export function MonthSelection() {
    const dispatch: StoreDispatch = useDispatch();
    const months: MonthOption[] = useSelector(selectMonthOptions);
    const activeMonthOption: MonthOption = useSelector(selectActiveMonthOption);

    useEffect(() => {
        dispatch(findBatchesByMonth(activeMonthOption));
    }, [dispatch, activeMonthOption]);

    const onChange = (index: string) => {
        dispatch(changeMonth(months[parseInt(index)]));
    }
    
    const index = months.indexOf(activeMonthOption);

    return (
        <div id="month-tab-container">
            <TabGroup activeId={index.toString()} name="month-tab-selection" onChange={e => onChange(e.target.id)}>
                {months.map((am, index) => <Tab id={index.toString()} key={index}>{`${am.year} - ${am.month.toString().padStart(2, '0')}`}</Tab>)}
            </TabGroup>
        </div>
    )
}