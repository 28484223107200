import './ActionContainer.css';

import { Button } from '@nike/eds';
import { useDispatch, useSelector } from 'react-redux';
import { MonthSelection } from '../month_selection/MonthSelection';
import { LocalClearanceBatch } from '../../shared/Types';
import { StoreDispatch } from '../../shared/store/Store';
import { selectCheckedBatches, toggleModalConfirmation } from '../../shared/store/reducers/OverviewReducer';

export function ActionContainer() {
    const dispatch: StoreDispatch = useDispatch();
    const batches = useSelector(selectCheckedBatches) as LocalClearanceBatch[];

    const onClickDeclare = () => {
        dispatch(toggleModalConfirmation(true));
    }

    return (
        <div id="action-container">
            <MonthSelection/>

            <div id="action-button-container">
                <span className="eds-type--body-3">{batches.length} items selected</span>
                
                <Button id="submit-button" onClick={onClickDeclare} disabled={batches.length === 0}>
                    Release selected batches for declaration
                </Button>
            </div>
        </div>
    )
}