import './BatchTable.css';

import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Table, TableCell, TableHeading, Spinner, Card } from '@nike/eds';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { LocalClearanceBatch } from '../../shared/Types';
import { BatchStatusIndicator } from '../batch_status_indicator/BatchStatusIndicator';
import { StoreDispatch } from '../../shared/store/Store';
import { toggleBatch, selectIsLoading, selectBatches } from '../../shared/store/reducers/OverviewReducer';

export function BatchTable() {
    const dispatch: StoreDispatch = useDispatch();
    const data = useSelector(selectBatches) as LocalClearanceBatch[];
    const isLoading = useSelector(selectIsLoading) as boolean;

    const onChange = (batch: LocalClearanceBatch) => {
        dispatch(toggleBatch(batch));
    }

    const columnHelper = createColumnHelper<LocalClearanceBatch>();
    const columns = [
        columnHelper.display({
            id: 'select_batch',
            header: () => '',
            cell: (props) =>  {
                const batch = props.row.original;
                return batch.status === "NEW" ? <Checkbox id={props.row.id} label="" value={batch.day_bucket_date} checked={batch.selected} onChange={e => onChange(batch)}/> : null;
            },
            minSize: 0,
            maxSize: 25,
        }),
        columnHelper.accessor("day_bucket_date", {
            header: "EiDR batch",
            id: "eidr_batch",
            minSize: 0,
            maxSize: 50,
        }),
        columnHelper.accessor("user_id", {
            header: "Released by",
            id: "released_by",
            minSize: 0,
            maxSize: 150,
        }),
        columnHelper.accessor("status", {
            header: "Release status",
            id: "release_status",
            cell: props => <BatchStatusIndicator {...props.row.original}/>
        })
    ];

    const {getHeaderGroups, getRowModel} = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel()
    });

    return (
        <div>
            <div id="loader" className={!isLoading ? 'hidden' : ''}>
                <Spinner size="large" />
            </div>
            <Card id="message-no-batches" className={isLoading || data.length > 0 ? 'hidden' : ''}>
                <p className="'text-center'">No batches available for selected month, because it is the first of the month.</p>
            </Card>
            <Table id="batch-table" className={isLoading || data.length === 0 ? 'hidden' : ''}>
                <thead>
                {getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header, index) => (
                                <TableHeading
                                    key={header.id}
                                    style={{
                                        minWidth: header.column.columnDef.minSize,
                                        maxWidth: header.column.columnDef.maxSize
                                    }}
                                    className={`eds-type--subtitle-1 eds-color--default ${index === 0 ? "table-action-header" : "table-header"}`}
                                >
                                {flexRender(header.column.columnDef.header, header.getContext())}
                            </TableHeading>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody>
                {getRowModel().rows.map(row => (
                    <tr key={row.id}>
                        {row.getVisibleCells().map((cell, index) => (
                            <TableCell
                                key={cell.id}
                                className={`eds-type--body-3 ${index === 0 ? "table-action-data" : "table-data"}`}
                                style={{
                                    minWidth: cell.column.columnDef.minSize,
                                    maxWidth: cell.column.columnDef.maxSize
                                }}
                            >
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                        ))}
                    </tr>
                ))}
                </tbody>
            </Table>
        </div>
    )
}